<template>
  <el-card shadow="never">
    <el-row>
      <div class="date">资产码：</div>
      <el-input type="textarea" placeholder="请输入资产码，如有多个资产码，每个资产码一行" v-model="assetSnText" clearable style="width: 240px"></el-input>
      <el-button type="primary" @click="exportData" :loading="loading" class="btn">导出检验数据</el-button>
    </el-row>
  </el-card>
</template>

<script>
export default {
  name: "RepeatedTest",
  data() {
    return {
      assetSnText: '',
      loading: false
    }
  },
  methods: {
    exportData() {
      if (!this.assetSnText) {
        return this.$message.error('资产码不能为空！');
      }
      if (this.assetSnText.length > 12500) {
        return this.$message.error('资产码过多，请分批导出');
      }
      this.loading = true;
      this.$axios.post('report/expRepeatedTestReport', this.assetSnText,{timeout: 600000}).then(response => {
        this.loading = false;
        let res = response.data;
        if (res.code !== 0) {
          return this.$message.error(res.message);
        }

        if (res.data.url) {
          const link = document.createElement('a');
          link.href = res.data.url;
          document.body.appendChild(link);
          link.click();
          URL.revokeObjectURL(link.href);
          document.body.removeChild(link);

        }
      }, error => {
        this.loading = false;
        return this.$message.error('导出失败，' + error.message);
      });
    }
  }
}
</script>

<style scoped>
.btn {
  margin-left: 40px;
}

.date {
  display: inline-block;
  width: 120px;
  text-align: right;
}
</style>