<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="12">
        <el-row class="row">
          <TableTitleComponent title="每日产能报表"/>
          <DayCapacity/>
        </el-row>
        <el-row class="row">
          <TableTitleComponent title="已出库时效报表"/>
          <ReturnAgingReport/>
        </el-row>
        <el-row class="row">
          <TableTitleComponent title="不良检出率报表"/>
          <NgRateReport/>
        </el-row>
        <el-row class="row">
          <TableTitleComponent title="导出物品数据"/>
          <MaterialReport/>
        </el-row>
        <el-row class="row">
          <TableTitleComponent title="导出可回收包材物品数据"/>
          <RecyclableMaterialReport/>
        </el-row>
        <el-row class="row">
          <TableTitleComponent title="导出配置产能"/>
          <ConfigCapacity/>
        </el-row>
        <el-row class="row">
          <TableTitleComponent title="导出作业异常"/>
          <BusItemIssue/>
        </el-row>
        <el-row class="row">
          <TableTitleComponent title="导出3次以上进站数据"/>
          <RepeatedThree/>
        </el-row>
        <el-row class="row">
          <TableTitleComponent title="校色屏报表"/>
          <ColorCalibrationReport/>
        </el-row>
        <el-row class="row">
          <TableTitleComponent title="键盘ATE报表"/>
          <BoardAteReport/>
        </el-row>
        <el-row class="row">
          <TableTitleComponent title="导出DOA外挂数据"/>
          <DoaAgingReport/>
        </el-row>
        <el-row class="row">
          <TableTitleComponent title="报废再利用报表"/>
          <RecycleReport/>
        </el-row>
      </el-col>
      <el-col :span="12">
        <el-row class="row">
          <TableTitleComponent title="当前在库时效报表"/>
          <InStoreAgingReport/>
        </el-row>
        <el-row class="row">
          <TableTitleComponent title="未分拣时效报表"/>
          <NotSortAgingReport/>
        </el-row>
        <el-row class="row">
          <TableTitleComponent title="归还已录入未回绑报表"/>
          <NotBindReport/>
        </el-row>
        <el-row class="row">
          <TableTitleComponent title="备件包材导出"/>
          <PackMaterialRelationReport/>
        </el-row>
        <el-row class="row">
          <TableTitleComponent title="导出DOA检验数据"/>
          <DoaDataReport/>
        </el-row>
        <el-row class="row">
          <TableTitleComponent title="导出键盘进出存报表"/>
          <KeyboardInOutReport/>
        </el-row>
        <el-row class="row">
          <TableTitleComponent title="键盘当前在库时效报表"/>
          <KeyboardInStoreAgingReport/>
        </el-row>
        <el-row class="row">
          <TableTitleComponent title="导出投产报表"/>
          <WaitKeyInReport/>
        </el-row>
        <el-row class="row">
          <TableTitleComponent title="导出多次进站检验数据"/>
          <RepeatedTest/>
        </el-row>
        <el-row class="row">
          <TableTitleComponent title="导出IQC检验数据"/>
          <IqcDataReport/>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import DayCapacity from "@/views/report/DayCapacity";
import TableTitleComponent from "@/components/TableTitleComponent";
import NgRateReport from "@/views/report/NgRateReport";
import NotBindReport from "@/views/report/NotBindReport";
import InStoreAgingReport from "@/views/report/InStoreAgingReport";
import ReturnAgingReport from "@/views/report/ReturnAgingReport";
import NotSortAgingReport from "@/views/report/NotSortAgingReport";
import MaterialReport from "@/views/report/MaterialReport";
import ConfigCapacity from "@/views/report/ConfigCapacity";
import RepeatedTest from "@/views/report/RepeatedTest";
import RecyclableMaterialReport from "@/views/report/RecyclableMaterialReport";
import PackMaterialRelationReport from "@/views/report/PackMaterialRelationReport";
import DoaDataReport from "@/views/report/DoaDataReport";
import BusItemIssue from "@/views/report/BusItemIssue";
import RepeatedThree from "@/views/report/RepeatedThree.vue";
import IqcDataReport from "@/views/report/IqcDataReport.vue";
import ColorCalibrationReport from "@/views/report/ColorCalibrationReport.vue";
import BoardAteReport from "@/views/report/BoardAteReport.vue";
import KeyboardInOutReport from "@/views/report/KeyboardInOutReport.vue";
import KeyboardInStoreAgingReport from "@/views/report/KeyboardInStoreAgingReport.vue";
import WaitKeyInReport from "@/views/report/WaitKeyInReport.vue";
import DoaAgingReport from "@/views/report/DoaAgingReport.vue";
import RecycleReport from "@/views/report/RecycleReport.vue";

export default {
  name: "ExportReport",
  components: {
    RecycleReport,
    DoaAgingReport,
    WaitKeyInReport,
    KeyboardInStoreAgingReport,
    KeyboardInOutReport,
    BoardAteReport,
    ColorCalibrationReport,
    RepeatedThree,
    BusItemIssue,
    DoaDataReport,
    IqcDataReport,
    PackMaterialRelationReport,
    RecyclableMaterialReport,
    RepeatedTest,
    ConfigCapacity,
    MaterialReport,
    NotSortAgingReport,
    ReturnAgingReport,
    InStoreAgingReport,
    NotBindReport, NgRateReport,
    TableTitleComponent,
    DayCapacity
  }
}
</script>

<style scoped>
.row {
  padding-bottom: 20px;
}
</style>