<template>
  <el-card shadow="never">
    <el-row>
      <div class="date">产能日期：</div>
      <el-date-picker
          v-model="capacityDay"
          type="daterange"
          :clearable=false
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          style="width: 400px">
      </el-date-picker>
      <el-button type="primary" @click="exportData" :loading="loading" class="btn">导出配置产能</el-button>
    </el-row>
  </el-card>
</template>

<script>
import {currentDay} from "@/utils/common";

export default {
  name: "ConfigCapacity",
  data() {
    return {
      capacityDay: [currentDay(), currentDay()],
      loading: false
    }
  },
  methods: {
    exportData() {
      if (!this.capacityDay || this.capacityDay.length != 2) {
        return this.$message.error('请选择日期');
      }

      this.loading = true;
      this.$axios.post('report/expConfigCapacity', {
        beginDate: this.capacityDay[0],
        endDate: this.capacityDay[1],
      }).then(response => {
        this.loading = false;
        let res = response.data;
        if (res.code !== 0) {
          return this.$message.error(res.message);
        }

        if (res.data.url) {
          const link = document.createElement('a');
          link.href = res.data.url;
          document.body.appendChild(link);
          link.click();
          URL.revokeObjectURL(link.href);
          document.body.removeChild(link);

        }
      }, error => {
        this.loading = false;
        return this.$message.error('导出失败，' + error.message);
      });

    }
  }
}
</script>

<style scoped>
.btn {
  margin-left: 40px;
}

.date {
  display: inline-block;
  width: 120px;
  text-align: right;
}
</style>