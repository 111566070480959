<template>
  <el-card shadow="never">
    <el-row>
      <el-select v-model="dateType" class="title" filterable>
        <el-option value="sort" label="分拣日期"></el-option>
        <el-option value="appearance" label="外观日期"></el-option>
        <el-option value="electric" label="电性能日期"></el-option>
        <el-option value="pack" label="包装日期"></el-option>
        <el-option value="return" label="归还日期"></el-option>
      </el-select>
      <el-date-picker
          v-model="sortDay"
          type="datetimerange"
          :clearable=false
          value-format="yyyy-MM-dd HH:mm:ss"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00', '23:59:59']">
      </el-date-picker>
      <el-button type="primary" @click="exportData" :loading="loading" class="btn">导出物品数据</el-button>
    </el-row>
  </el-card>
</template>

<script>
import {betweenDays, currentDay} from "@/utils/common";

export default {
  name: "MaterialReport",
  data() {
    return {
      sortDay: [currentDay() + ' 00:00:00', currentDay() + ' 23:59:59'],
      dateType: 'sort',
      loading: false
    }
  },
  methods: {
    exportData() {
      if (!this.sortDay || this.sortDay.length != 2) {
        return this.$message.error('请选择日期');
      }
      if (betweenDays(this.sortDay[0], this.sortDay[1]) > 10) {
        return this.$message.error('日期范围不能超过10天，请分批导出。');
      }

      this.loading = true;
      this.$axios.post('report/expMaterialReport', {
        dateType: this.dateType,
        beginTime: this.sortDay[0],
        endTime: this.sortDay[1],
      }, {timeout: 180000}).then(response => {
        this.loading = false;
        let res = response.data;
        if (res.code !== 0) {
          return this.$message.error(res.message);
        }

        if (res.data.url) {
          const link = document.createElement('a');
          link.href = res.data.url;
          document.body.appendChild(link);
          link.click();
          URL.revokeObjectURL(link.href);
          document.body.removeChild(link);

        }
      }, error => {
        this.loading = false;
        return this.$message.error('导出失败，' + error.message);
      });

    }
  }
}
</script>

<style scoped>
.btn {
  margin-left: 40px;
}

.title {
  margin-right: 5px;
  width: 120px;
}
</style>